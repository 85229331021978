<script>
import { genericForms } from '@shared/mixins/forms';
import APIStore from '@app/services/API/Store';

export default {
  mixins: [genericForms],
  props: {
    customer: {
      type: Object,
      default: null,
    },
    training: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isFetching: false,
      newCustomer: {
        training_uuids: this.training ? [this.training.uuid] : [],
        firstname: '',
        lastname: '',
        email: '',
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    trainings() {
      const { list } = this.$store.state.trainings;
      return list ? list.data : null;
    },
  },
  created() {
    if (this.training) {
      return;
    }

    this.isFetching = true;
    this.$store.dispatch('trainings/fetch')
      .finally(() => (this.isFetching = false));
  },
  methods: {
    action() {
      let promise;

      if (this.customer) {
        const customer = {
          firstname: this.newCustomer.firstname || undefined,
          lastname: this.newCustomer.lastname || undefined,
          training_uuids: [...this.newCustomer.training_uuids],
        };

        promise = APIStore.addCustomerTrainings(this.authStore.uuid, this.customer.uuid, customer);
      } else {
        const customer = { ...this.newCustomer };

        promise = APIStore.addCustomer(this.authStore.uuid, customer);
      }

      return promise.then(() => this.$emit('add'));
    },
  },
};
</script>

<template>
  <div class="box">
    <h1 class="title is-4">
      Inviter
      <template v-if="customer && customer.firstname">
        {{ customer.firstname }}
      </template>
      <template v-else>
        un apprenant
      </template>

      <AppFeatureBadge class="ml-3" position="is-bottom" />
    </h1>
    <p v-if="!customer" class="mb-3">
      L'apprenant ajouté recevra ses identifiants par email.
      Il sera invité à changer son mot de passe à sa première connexion.
    </p>
    <p class="mb-3">
      L'apprenant aura <strong>immédiatement accès</strong> à la formation.
    </p>
    <p>
      Si l'apprenant a déjà accès à une de vos formations,
      et que vous l'invitez à nouveau dans cette même formation,
      il ne recevra pas de nouvel email.
    </p>
    <go-pro-wrapper>
      <hr>
      <p
        v-if="training && training.status === 'DRAFT'"
        class="notification is-warning"
      >
        Vous ne pouvez pas inviter des apprenants
        dans une formation qui est en brouillon.
      </p>
      <form v-else @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field v-if="!training" label="Formations">
          <BaseMultipleSelect
            v-model="newCustomer.training_uuids"
            label="Choisissez les formations"
            :loading="isFetching"
            :floating="!customer"
            required
          >
            <BaseMultipleSelectOption
              v-for="one in trainings"
              :key="one.uuid"
              :value="one.uuid"
              :disabled="one.status === 'DRAFT'"
            >
              {{ one.name }}
              <span
                v-if="one.status === 'DRAFT'"
                class="tag has-text-weight-normal ml-2 is-size-9">
                <b-icon
                  class="mr-1 is-size-9"
                  icon="circle"
                  type="is-warning"
                />
                brouillon
              </span>
            </BaseMultipleSelectOption>
          </BaseMultipleSelect>
        </b-field>
        <b-field v-if="!customer || !customer.firstname" label="Prénom">
          <b-input v-model="newCustomer.firstname" required />
        </b-field>
        <b-field v-if="!customer || !customer.firstname" label="Nom">
          <b-input v-model="newCustomer.lastname" required />
        </b-field>
        <b-field v-if="!customer" label="Adresse email">
          <b-input type="email" v-model="newCustomer.email" required />
        </b-field>
        <div class="buttons is-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Envoyer
          </b-button>
        </div>
      </form>
    </go-pro-wrapper>
  </div>
</template>

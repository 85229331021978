<script>
import { required } from 'vuelidate/lib/validators';
import { genericForms } from '@shared/mixins/forms';
import i18n from '@app/config/i18n';
import APITrainings from '@app/services/API/Trainings';

export default {
  mixins: [genericForms],
  props: {
    customer: {
      type: Object,
      required: true,
    },
    training: {
      type: Object,
      required: false,
    },
  },
  form: {
    client_full_name: {
      label: i18n.t('forms.training_document.client_full_name'),
      type: 'text',
      column: 'is-12',
      inputAttrs: {
        required: true,
      },
    },
    client_company_name: {
      label: i18n.t('forms.training_document.client_company_name'),
      type: 'text',
      column: 'is-12',
      inputAttrs: {
        required: true,
      },
    },
    training_name: {
      label: i18n.t('forms.training_document.training_name'),
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        required: true,
      },
    },
    training_nb_hours: {
      label: i18n.t('forms.training_document.training_nb_hours'),
      type: 'number',
      column: 'is-6',
      inputAttrs: {
        required: true,
      },
    },
    training_nature: {
      label: i18n.t('forms.training_document.training_nature'),
      type: 'select',
      column: 'is-12',
      inputAttrs: {
        required: true,
      },
      options: {
        action_formation: i18n.t('forms.training_document.training_nature_options.action_formation'),
        vae: i18n.t('forms.training_document.training_nature_options.vae'),
        apprentissage: i18n.t('forms.training_document.training_nature_options.apprentissage'),
        bilan: i18n.t('forms.training_document.training_nature_options.bilan'),
      },
    },
    training_begin_date: {
      label: i18n.t('forms.training_document.training_begin_date'),
      type: 'datepicker',
      column: 'is-6',
      errors: ['required'],
      inputAttrs: {
        position: 'is-top-right',
        required: true,
      },
    },
    training_end_date: {
      label: i18n.t('forms.training_document.training_end_date'),
      type: 'datepicker',
      column: 'is-6',
      errors: ['required'],
      inputAttrs: {
        position: 'is-top-left',
        required: true,
      },
    },
    company_name: {
      label: i18n.t('forms.training_document.company_name'),
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        required: true,
      },
    },
    trainer_full_name: {
      label: i18n.t('forms.training_document.trainer_full_name'),
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        required: true,
      },
    },
    company_address: {
      label: i18n.t('forms.training_document.company_address'),
      type: 'text',
      column: 'is-12',
      inputAttrs: {
        required: true,
      },
    },
    siret: {
      label: i18n.t('forms.training_document.siret'),
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        required: true,
      },
    },
    nda: {
      label: i18n.t('forms.training_document.nda'),
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        required: true,
      },
    },
    signatory_full_name: {
      label: i18n.t('forms.training_document.signatory_full_name'),
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        required: true,
      },
    },
    signatory_function: {
      label: i18n.t('forms.training_document.signatory_function'),
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        required: true,
      },
    },
    city_signature: {
      label: i18n.t('forms.training_document.city_signature'),
      type: 'text',
      column: 'is-6',
      inputAttrs: {
        required: true,
      },
    },
    date_signature: {
      label: i18n.t('forms.training_document.date_signature'),
      type: 'datepicker',
      column: 'is-6',
      errors: ['required'],
      inputAttrs: {
        position: 'is-top-left',
        required: true,
      },
    },
  },
  data() {
    return {
      isFetchingTrainings: false,
      isFetchingAttendanceData: false,
      trainingUuid: this.training ? this.training.uuid : '',
      errorMessage: '',
      attendanceData: {
        client_full_name: '',
        client_company_name: '',
        training_name: '',
        company_name: '',
        trainer_full_name: '',
        company_address: '',
        siret: '',
        nda: '',
        signatory_full_name: '',
        signatory_function: '',
        training_nb_hours: '',
        training_nature: 'action_formation',
        training_begin_date: null,
        training_end_date: null,
        city_signature: '',
        date_signature: null,
      },
    };
  },
  validations: {
    attendanceData: {
      training_begin_date: { required },
      training_end_date: { required },
      date_signature: { required },
    },
  },
  watch: {
    trainingUuid: {
      immediate: true,
      handler(uuid) {
        this.errorMessage = '';

        if (!uuid) {
          return;
        }

        this.isFetchingAttendanceData = true;
        APITrainings.getCustomerAttendanceData(uuid, this.customer.uuid)
          .then(({ data = {} }) => {
            Object.keys(data)
              .forEach((k) => {
                if (k in this.attendanceData) {
                  const isDate = (
                    this.$options.form[k]
                    && this.$options.form[k].type === 'datepicker'
                  );

                  if (isDate) {
                    this.attendanceData[k] = data[k] ? this.$moment(data[k]).toDate() : null;
                    return;
                  }

                  this.attendanceData[k] = data[k];
                }
              });
          })
          .catch((error) => {
            const { data } = error.response;

            if (data.code === 'MISSING_COMPANY_DETAILS_FOR_DOC_GENERATE') {
              this.errorMessage = data.message;
              return;
            }

            this.$errorHandlers.axios(error);
          })
          .finally(() => (this.isFetchingAttendanceData = false));
      },
    },
  },
  methods: {
    action() {
      const data = { ...this.attendanceData };

      if (data.date_signature) {
        data.date_signature = this.$moment(data.date_signature).format('YYYY-MM-DD');
      }

      if (data.training_begin_date) {
        data.training_begin_date = this.$moment(data.training_begin_date).format('YYYY-MM-DD');
      }

      if (data.training_end_date) {
        data.training_end_date = this.$moment(data.training_end_date).format('YYYY-MM-DD');
      }

      return APITrainings.exportCustomerTrainingDocument(
        this.trainingUuid, this.customer.uuid, data,
      )
        .then(() => this.$emit('close'));
    },
  },
};
</script>

<template>
  <form class="modal-card" @submit.prevent="dataIsValid(isLoading) && handle()">
    <header class="modal-card-head">
      <h1 class="modal-card-title">
        Générer un certificat de réalisation
      </h1>
    </header>
    <div class="modal-card-body">
      <p class="mb-3">
        Nous générons et nous vous envoyons <strong>par email</strong> un certificat de réalisation,
        tenant compte des logs de connexion de l'apprenant.
      </p>
      <p class="mb-3">
        Cette attestation est nécessaire pour justifier,
        auprès des financeurs (OPCO, FAF ou CPF), que l'apprenant a suivi l'action de formation.
      </p>
      <p class="mb-3">
        Le traitement va durer quelques secondes.
      </p>
      <p>
        <strong>Tous les champs sont obligatoires</strong>.
      </p>
      <go-pro-wrapper>
        <hr>
        <div class="columns is-multiline">
          <div class="column is-12 mb-0">
            <b-field label="Formation" label-for="trainingUuid">
              <b-select id="trainingUuid" v-model="trainingUuid" required expanded>
                <option value="">
                  Choisissez une formation
                </option>
                <option v-for="one in customer.customer_trainings" :key="one.uuid" :value="one.uuid">
                  {{ one.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <template v-if="trainingUuid">
            <div v-if="isFetchingAttendanceData" class="column mb-0">
              <b-skeleton height="400" />
            </div>
            <template v-else>
              <div v-if="errorMessage" class="column">
                <div class="box content is-danger">
                  <p>{{ errorMessage }}</p>
                  <b-button
                    type="is-primary"
                    tag="router-link"
                    :to="{name: 'settings_company'}"
                    @click.native="$emit('close')">
                    Compléter mes informations
                  </b-button>
                </div>
              </div>
              <template v-else>
                <div v-for="(field, k) in $options.form" :key="k" class="column mb-0" :class="field.column">
                  <BaseField v-model="attendanceData[k]" :v="$v.attendanceData[k]" :field="field" />
                </div>
              </template>
            </template>
          </template>
        </div>
      </go-pro-wrapper>
    </div>
    <footer class="modal-card-foot is-justify-content-flex-end btop-grey">
      <b-button
        type="is-primary"
        native-type="submit"
        :loading="isLoading"
        :disabled="$store.getters['auth/hasPercentPlan'] || !!errorMessage">
        Envoyer
      </b-button>
    </footer>
  </form>
</template>

<script>
import APIStore from '@app/services/API/Store';
import APITrainings from '@app/services/API/Trainings';
import CustomerAddForm from '@app/components/CustomerAddForm.vue';
import CustomerAttendanceForm from '@app/components/CustomerAttendanceForm.vue';
import CustomerTrainingDocumentForm from '@app/components/CustomerTrainingDocumentForm.vue';
import CustomerTrainingTransactions from '@app/components/CustomerTrainingTransactions.vue';

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
    training: {
      type: Object,
      default: null,
    },
  },
  computed: {
    currentCustomerTraining() {
      if (this.training) {
        return this.customer
          .customer_trainings
          .find((training) => training.uuid === this.training.uuid);
      }

      return null;
    },
    isBlockedInTraining() {
      if (this.training) {
        return this.currentCustomerTraining.customer_type === 'BLOCKED';
      }

      return null;
    },
    isAbandonedCartInTraining() {
      if (this.training) {
        return this.currentCustomerTraining.customer_type === 'ABANDONED_CART';
      }

      return null;
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  methods: {
    removeCustomer() {
      const loader = this.$buefy.loading.open();
      APITrainings.getCustomerTransactions(this.training.uuid, this.customer.uuid)
        .then(({ data }) => {
          const transactions = data || [];
          const pendingTransactions = transactions.find((v) => v.status === 'IN_PROGRESS');

          if (!pendingTransactions) {
            this.showConfirmCustomerRemoveDialog();
            return;
          }

          this.showPendingTransactionsDialog(transactions);
        })
        .finally(() => loader.close());
    },
    showPendingTransactionsDialog(transactions) {
      this.$buefy.dialog.confirm({
        title: 'Il reste des paiements',
        message: `
          <p class="mb-3">
            Cet apprenant a encore des paiements en attente (paiement en plusieurs fois).
          </p>
          <p class="mb-3">
            Par défaut, les prochaines échéances seront traitées même si vous retirez cet apprenant.
          </p>
          <p>
            Voulez-vous annuler le ou les paiements à venir ?
          </p>
        `,
        type: 'is-danger',
        focusOn: 'confirm',
        canCancel: ['button'],
        cancelText: 'Non, garder les paiements',
        confirmText: 'Oui',
        onCancel: this.showConfirmCustomerRemoveDialog,
        onConfirm: () => this.showTransactions(transactions),
      });
    },
    showTransactions(transactions) {
      const { training, customer } = this;
      this.$buefy.modal.open({
        component: CustomerTrainingTransactions,
        parent: this,
        width: '768px',
        props: { transactions, training, customer },
      });
    },
    showConfirmCustomerRemoveDialog() {
      this.$buefy.dialog.confirm({
        title: 'Action définitive',
        message: `
          <p class="mb-3">
            En retirant cet apprenant, il/elle n'aura plus accès à la formation
            et les informations à son sujet seront supprimées <strong>définitivement</strong>.
          </p>
          <p>
            Si vous voulez uniquement bloquer ses accès à la formation,
            choisissez "<strong>Bloquer l'accès à la formation</strong>" dans le menu.
          </p>
        `,
        type: 'is-danger',
        focusOn: 'cancel',
        confirmText: 'Confirmer le retrait',
        onConfirm: () => {
          const loader = this.$buefy.loading.open();
          APITrainings.removeCustomer(this.training.uuid, this.customer.uuid)
            .then(() => {
              this.$emit('delete', this.customer);
              this.$showMessage.success();
            })
            .finally(() => loader.close());
        },
      });
    },
    changeStatus(type) {
      const loader = this.$buefy.loading.open();
      APITrainings.updateCustomer(this.training.uuid, this.customer.uuid, { type })
        .then(({ data }) => this.$emit('update-status', data))
        .finally(() => {
          this.$buefy.dialog.alert(`
            L'apprenant <strong>${this.customer.firstname} ${this.customer.lastname || ''}</strong>
            ${type === 'BLOCKED' ? 'n\'a désormais plus' : 'a de nouveau'}
            accès à la formation <strong>${this.training.name}</strong>.
          `);
          loader.close();
        });
    },
    toggleBypass() {
      const bypass_drip = !this.currentCustomerTraining.bypass_drip;
      const loader = this.$buefy.loading.open();
      APITrainings.updateCustomer(this.training.uuid, this.customer.uuid, { bypass_drip })
        .then(({ data }) => this.$emit('update-status', data))
        .finally(() => {
          this.$buefy.dialog.alert(`
            Les délais d'accès de
            <strong>${this.customer.firstname} ${this.customer.lastname || ''}</strong>
            ont bien été modifié concernant la formation <strong>${this.training.name}</strong>.
          `);
          loader.close();
        });
    },
    addToTrainings() {
      const modal = this.$buefy.modal.open({
        component: CustomerAddForm,
        parent: this,
        width: '460px',
        props: { customer: this.customer },
        events: {
          add: () => {
            modal.close();
          },
        },
      });
    },
    exportLogs() {
      const loader = this.$buefy.loading.open();
      const trainingUUID = this.training ? this.training.uuid : undefined;
      APIStore.exportCustomerLogs(this.authStore.uuid, this.customer.uuid, trainingUUID)
        .finally(() => {
          this.$buefy.dialog.alert(`
            Les logs de connexion de
            <strong>${this.customer.firstname} ${this.customer.lastname || ''}</strong>
            vous ont été envoyés par email.
          `);
          loader.close();
        });
    },
    exportQualiopi() {
      const loader = this.$buefy.loading.open();
      APITrainings.exportQualiopi(this.training.uuid, this.customer.uuid)
        .then(() => {
          this.$buefy.dialog.alert(`
            Les preuves Qualiopi pour
            <strong>${this.customer.firstname} ${this.customer.lastname || ''}</strong>
            vous ont été envoyés par email.
          `);
        })
        .finally(() => {
          loader.close();
        });
    },
    exportAttendance() {
      const { customer, training } = this;
      this.$buefy.modal.open({
        parent: this,
        width: 640,
        hasModalCard: true,
        props: { customer, training },
        component: CustomerAttendanceForm,
      });
    },
    exportDocument(documentType) {
      const { customer, training } = this;
      this.$buefy.modal.open({
        parent: this,
        width: 640,
        hasModalCard: true,
        props: { customer, training, documentType },
        component: CustomerTrainingDocumentForm,
      });
    },
  },
};
</script>

<template>
  <b-dropdown
    class="mr-3"
    position="is-bottom-left"
    aria-role="menu"
    append-to-body
  >
    <template #trigger>
      <b-button type="is-text" icon-left="ellipsis-h" />
    </template>
    <!-- <b-dropdown-item aria-role="menuitem" @click="exportLogs(customer)">
      Voir les détails
    </b-dropdown-item> -->
    <b-dropdown-item v-if="!training" aria-role="menuitem" @click="addToTrainings">
      Inviter dans des formations <AppFeatureBadge class="ml-3" />
    </b-dropdown-item>
    <b-dropdown-item aria-role="menuitem" @click="exportLogs">
      Exporter les logs de connexion
    </b-dropdown-item>
    <b-dropdown-item v-if="training" aria-role="menuitem" @click="exportQualiopi">
      Exporter les preuves Qualiopi
    </b-dropdown-item>
    <b-dropdown-item aria-role="menuitem" @click="exportDocument('CUSTOMER_ACHIEVEMENT_CERTIFICATE')">
      Générer un certificat de réalisation
    </b-dropdown-item>
    <b-dropdown-item aria-role="menuitem" @click="exportAttendance">
      Générer une attestation d'assiduité
    </b-dropdown-item>
    <template v-if="training">
      <b-dropdown-item separator />
      <b-dropdown-item aria-role="menuitem" @click="$emit('show-progression')">
        Voir la progression
      </b-dropdown-item>
      <b-dropdown-item separator />
      <template v-if="currentCustomerTraining">
        <b-dropdown-item v-if="$store.getters['auth/isNotPercentPlan']" aria-role="menuitem" @click="toggleBypass()">
          <span v-show="!currentCustomerTraining.bypass_drip">
            Outrepasser les délais d'accès
          </span>
          <span v-show="currentCustomerTraining.bypass_drip">
            Réappliquer les délais d'accès
          </span>
        </b-dropdown-item>
        <b-dropdown-item v-if="isBlockedInTraining" aria-role="menuitem" @click="changeStatus('STUDENT')">
          <span>Débloquer l'accès à la formation</span>
        </b-dropdown-item>
        <b-dropdown-item v-else aria-role="menuitem" @click="changeStatus('BLOCKED')">
          <span>Bloquer l'accès à la formation</span>
        </b-dropdown-item>
      </template>
      <b-dropdown-item v-if="!isAbandonedCartInTraining" aria-role="menuitem" @click="removeCustomer">
        <span class="has-text-danger">Retirer l'apprenant de la formation</span>
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

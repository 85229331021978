<script>
import moment from '@shared/config/moment';
import utilsMixin from '@shared/mixins/utils';
import quizMixin from '@app/mixins/quiz';

export default {
  mixins: [utilsMixin, quizMixin],
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    customer: {
      type: Object,
      required: true,
    },
    customerTraining: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    onlyShowTime: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => (() => {}),
    },
    onUnblockTrainingItem: {
      type: Function,
      default: () => (() => {}),
    },
  },
  getCompletedDate(item) {
    return moment.utc(item.completed_at).local().format('DD/MM/YY [à] HH:mm');
  },
  getQuizScorePercent(quiz) {
    return Math.round((quiz.nb_successful_questions / quiz.nb_questions) * 100);
  },
  getGroupedSessions(sessions) {
    return sessions.reduce((prev, curr) => {
      const key = moment.utc(curr.begin).local().format('DD/MM/YY');
      prev[key] = prev[key] || [];
      prev[key].push(curr);
      return prev;
    }, {});
  },
  formatTime(session) {
    const begin = moment.utc(session.begin).local().valueOf();
    const end = moment.utc(session.end).local().valueOf();
    return utilsMixin.filters.formatSeconds((end - begin) / 1000);
  },
};
</script>

<template functional>
  <component
    :is="props.tag"
    class="level is-mobile"
    :class="[data.staticClass, data.class]"
    :style="[data.staticStyle, data.style]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <div v-if="props.onlyShowTime">
      <b-tooltip
        position="is-right"
        :label="parent.$t(`training_items.types.${props.item.type}`)"
      >
        <b-icon :icon="parent.$constants.icons[props.item.type]" />
      </b-tooltip>
      <span class="is-size-8-mobile">{{ props.item.name }}</span>
      <div v-if="props.item.sessions.length" class="content mt-3">
        <template v-for="(sessions, key) in $options.getGroupedSessions(props.item.sessions)">
          <h3 class="is-size-6" :key="`h3${key}`">
            {{ key }}
          </h3>
          <ul :key="`ul${key}`" class="ml-5">
            <li v-for="(session, k) in sessions" :key="k">
              <strong>{{ $options.formatTime(session) }}</strong>
              (de {{ parent.$moment.utc(session.begin).local().format('HH:mm') }}
              à {{ parent.$moment.utc(session.end).local().format('HH:mm') }})
            </li>
          </ul>
        </template>
      </div>
      <div v-else class="mt-3 has-text-weight-bold">
        Pas encore de temps passé sur cette leçon.
      </div>
    </div>
    <template v-else>
      <div class="level-left is-flex-wrap-wrap is-flex-shrink-1">
        <b-tooltip
          position="is-right"
          :label="`Terminée le ${$options.getCompletedDate(props.item)}`"
          :active="!!props.item.completed_at"
        >
          <b-icon
            class="mr-1"
            :type="props.item.completed_at ? 'is-success' : 'is-grey'"
            :icon="props.item.completed_at ? 'check-circle' : 'circle'"
            :pack="props.item.completed_at ? '' : 'far'"
          />
        </b-tooltip>
        <b-tooltip
          position="is-right"
          :label="parent.$t(`training_items.types.${props.item.type}`)"
        >
          <b-icon class="mr-1" :icon="parent.$constants.icons[props.item.type]" />
        </b-tooltip>
        <span class="is-size-8-mobile">{{ props.item.name }}</span>
        <span class="tags ml-3 mb-0 mt-2">
          <a
            v-if="props.item.quiz_answers"
            class="tag is-outlined"
            href="#"
            @click.prevent="$options.staticExportQuizAnzswers(
              props.item.name, props.customerTraining.uuid, props.item.id, props.customer,
            )">
            <b-tooltip label="Exporter ses réponses">
              <b-icon icon="download" />
            </b-tooltip>
          </a>
          <span v-if="props.item.quiz_answers" class="tag border-grey-2">
            score
            {{ props.item.quiz_answers.nb_successful_questions }} / {{ props.item.quiz_answers.nb_questions }}
            ({{ $options.getQuizScorePercent(props.item.quiz_answers) }}%)
          </span>
          <span class="tag border-grey-2">
            temps passé {{ props.item.duration_in_second | formatSeconds }}
          </span>
          <span v-if="props.item.blocked_by_date" class="tag is-primary is-custom">
            <b-icon class="mr-1" icon="calendar-day" />
            accessible le {{ props.item.blocked_by_date | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </span>
          <span v-else-if="props.item.blocked_manually" class="tag is-primary is-custom">
            <b-icon class="mr-1" icon="calendar-day" />
            accessible après un déblocage manuel
          </span>
          <span v-else-if="props.item.blocked_by_delay" class="tag is-primary is-custom">
            <b-icon class="mr-1" icon="calendar-day" />
            accessible le {{ props.item.blocked_by_delay | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
          </span>
        </span>
      </div>

      <div class="level-right pl-3">
        <b-dropdown position="is-bottom-left">
          <template #trigger>
            <b-button
              type="is-text"
              icon-left="ellipsis-h"
            />
          </template>
          <b-dropdown-item
            v-if="props.item.blocked_by_date || props.item.blocked_by_delay || props.item.blocked_manually"
            @click="props.onUnblockTrainingItem">
            Débloquer la leçon pour cet apprenant
          </b-dropdown-item>
          <b-dropdown-item has-link>
            <router-link
              :to="{
                name: 'training',
                hash: `#training-item-${props.item.id}`,
                params: {
                  uuid: props.customerTraining.uuid,
                },
              }"
              @click.native="props.onClose()"
            >
              Voir la leçon
            </router-link>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </component>
</template>

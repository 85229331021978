<script>
import utilsMixin from '@shared/mixins/utils';
import { getItemsGroupedByType } from '@shared/services/Utils';
import APIStore from '@app/services/API/Store';
import CustomerTrainingCardItemLine from '@app/components/CustomerTrainingCardItemLine.vue';

export default {
  mixins: [utilsMixin],
  components: { CustomerTrainingCardItemLine },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    customerTraining: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localCustomerTraining: this.customerTraining,
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    completedPercent() {
      const one = this.customerTraining;
      return Math.round((one.completed_module_count / one.total_module_count) * 100);
    },
    localCustomerTrainingItemsByType() {
      return getItemsGroupedByType(
        this.localCustomerTraining.training_items,
      );
    },
  },
  watch: {
    customerTraining(val) {
      this.localCustomerTraining = val;
    },
  },
  methods: {
    onCardItemLineClose() {
      this.$emit('close');
    },
    makeOnUnblockTrainingItemHanler(trainingItem) {
      return () => {
        const loader = this.$buefy.loading.open();
        APIStore.unblockCustomerTrainingItem(
          this.authStore.uuid,
          this.customer.uuid,
          this.localCustomerTraining.uuid,
          trainingItem.id,
        )
          .then(({ data }) => {
            this.localCustomerTraining.training_items = this.localCustomerTraining
              .training_items
              .map((item) => {
                if (item.type === 'SECTION') {
                  item.children = item.children
                    .map((child) => (child.id === data.id ? data : child));

                  return item;
                }

                return item.id === data.id ? data : item;
              });
          })
          .finally(() => loader.close());
      };
    },
  },
};
</script>

<template>
  <div class="box">
    <h1 class="title is-4 is-mobile-6">
      Progression
    </h1>
    <p class="mb-5 is-size-8-mobile">
      {{ customer.firstname }} a terminé
      <strong>
        {{ localCustomerTraining.completed_module_count }} / {{ localCustomerTraining.total_module_count }} leçons
        ({{ completedPercent }}%)
      </strong>
      de la formation <strong>{{ localCustomerTraining.name }}</strong>.
    </p>
    <p class="mb-5 is-size-8-mobile">
      {{ customer.firstname }} a passé
      <strong>{{ localCustomerTraining.total_duration_in_second | formatSeconds }}</strong>
      au total sur la formation.
    </p>
    <h2 class="title is-5 is-mobile-6">
      Détails des leçons
    </h2>
    <div class="mt-5">
      <template v-for="(itemByType, k) in localCustomerTrainingItemsByType">
        <ul v-if="itemByType.type === 'ITEMS'" :key="`items${k}`" class="block">
          <li
            v-for="one in itemByType.items"
            :key="one.id"
            class="box mb-3 p-1"
          >
            <CustomerTrainingCardItemLine
              tag="div"
              :customer="customer"
              :customerTraining="localCustomerTraining"
              :item="one"
              :onClose="onCardItemLineClose"
              :onUnblockTrainingItem="makeOnUnblockTrainingItemHanler(one)"
            />
          </li>
        </ul>

        <template v-else>
          <p
            :key="`title${k}`"
            class="has-text-weight-bold is-uppercase mb-3">
            {{ itemByType.section.name }}
          </p>
          <ul :key="`items${k}`" class="block">
            <li
              v-for="one in itemByType.section.children"
              :key="one.id"
              class="box mb-3 p-1"
            >
              <CustomerTrainingCardItemLine
                tag="div"
                :customer="customer"
                :customerTraining="localCustomerTraining"
                :item="one"
                :onClose="onCardItemLineClose"
                :onUnblockTrainingItem="makeOnUnblockTrainingItemHanler(one)"
              />
            </li>
          </ul>
        </template>
      </template>
    </div>
  </div>
</template>

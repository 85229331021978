<script>
import theme from '@shared/assets/styles/theme';

export default {
  props: {
    max: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 40,
    },
    borderSize: {
      type: Number,
      default: 4,
    },
  },
  color: theme.color,
  getRadius(props) {
    return (props.size / 2) - (props.borderSize / 2);
  },
  getCircumference(props) {
    return 2 * Math.PI * this.getRadius(props);
  },
  getProgress(props) {
    return ((props.max - props.value) / props.max) * this.getCircumference(props);
  },
};
</script>

<template functional>
  <svg
    :class="[data.staticClass, data.class]"
    :style="[data.staticStyle, data.style]"
    v-bind="data.attrs"
    v-on="listeners"
    :width="props.size" :height="props.size" :viewBox="`0 0 ${props.size} ${props.size}`">
    <circle
      :cx="props.size / 2" :cy="props.size / 2"
      :r="$options.getRadius(props)"
      fill="none" :stroke="$options.color.grey_light" :stroke-width="props.borderSize"
    />
    <circle
      :cx="props.size / 2" :cy="props.size / 2"
      :r="(props.size / 2) - (props.borderSize / 2)"
      fill="none" :stroke="$options.color.primary" :stroke-width="props.borderSize"
      :stroke-dasharray="$options.getCircumference(props)"
      :stroke-dashoffset="$options.getProgress(props)"
    />
  </svg>
</template>

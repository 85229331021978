<script>
import EventBus from '@shared/services/EventBus';
import utilsMixin from '@shared/mixins/utils';
import APITrainings from '@app/services/API/Trainings';

export default {
  mixins: [utilsMixin],
  static: {
    transactions: {
      DRAFT: 'is-warning',
      PAID: 'is-success',
      FAILED: 'is-danger',
      INCOMING: 'is-warning',
      IN_PROGRESS: 'is-warning',
      ABANDONED: 'is-black',
    },
  },
  props: {
    customer: {
      type: Object,
      required: true,
    },
    training: {
      type: Object,
      required: true,
    },
    transactions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({ editedTransactions: [] }),
  computed: {
    root() {
      return this.transactions[0];
    },
    rootChildren() {
      const flattened = [];
      this.root.children.forEach((child) => {
        flattened.push(child);
        child.children.forEach((subchild) => flattened.push(subchild));
      });

      return flattened.map((v) => {
        const editedTransaction = this.editedTransactions.find((ev) => ev.id === v.id);

        if (editedTransaction) {
          return editedTransaction;
        }

        return v;
      });
    },
  },
  methods: {
    cancel(transaction) {
      this.$buefy.dialog.confirm({
        title: 'Action définitive',
        message: `
          <p>
            Attention ! L'annulation de ce paiement à venir est définitif.
          </p>
        `,
        focusOn: 'cancel',
        onConfirm: () => {
          const loader = this.$buefy.loading.open({ container: this.$el });
          APITrainings.cancelCustomerTransactions(
            this.training.uuid,
            this.customer.uuid,
            [transaction.id],
          )
            .then(() => {
              const editedTransaction = { ...transaction };
              editedTransaction.status = 'ABANDONED';
              this.editedTransactions.push(editedTransaction);

              EventBus.emit('customer-page-reload');
            })
            .finally(() => loader.close());
        },
      });
    },
  },
};
</script>

<template>
  <div class="box is-relative">
    <h1 class="title is-4 is-mobile-6">
      Détail des transactions
    </h1>
    <p>
      <strong>Initiée le</strong> : {{ root.created_at | momentFromUTC | moment('DD/MM/YY [à] HH[h]mm') }}
    </p>
    <p class="mt-2">
      <strong>Montant total (TTC)</strong> : {{ root.amount | formatPrice }}
      <template v-if="root.fees_amount">
        ({{ root.fees_amount | formatPrice }} de commission à prélever)
      </template>
    </p>
    <div>
      <p class="mt-2">
        <strong>Historique</strong> :
      </p>
      <ul>
        <template v-for="child in rootChildren">
          <li
            :key="`child${child.id}`"
            class="is-flex is-align-items-center is-justify-content-space-between mt-5">
            <div class="is-flex">
              <div
                class="tag w-100 mr-3"
                :class="$options.static.transactions[child.status]"
                v-t="`transactions.status.${child.status}`"
              />
              <p>
                <strong>
                  {{ child.amount | formatPrice }}
                </strong>
                <template v-if="root.fees_amount">
                  ({{ root.fees_amount | formatPrice }} de commission à prélever)
                </template>
                <br>
                le
                <strong>
                  {{ child.payment_date | momentFromUTC | moment('DD/MM/YY [à] HH[h]mm') }}
                </strong>
              </p>
            </div>
            <p v-if="child.status == 'INCOMING'">
              <b-button type="is-danger" outlined @click="cancel(child)">
                Supprimer
              </b-button>
            </p>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
